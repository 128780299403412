@import "_variables.scss";

*,
::after,
::before {
  box-sizing: border-box;
  outline: 0 !important;
}

hr:not([size]) {
  height: 1px;
}

p {
  margin-top: 0;
}

ol,
ul {
  padding-left: 2rem;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

.small,
small {
  font-size: 0.875em;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img,
svg {
  vertical-align: middle;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: none;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

body {
  &.kendo-dialog-open {
    overflow: hidden;
  }
}

.content-wrapper {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 15px 25px;
}
.container-fluid {
  padding: 0 20px;
}

.content-part {
  // background-color: #F4F8FB;
  // Headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    color: $darkhead;
  }
  h1 {
    font-size: $h1;
    line-height: 28px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  h2 {
    font-size: $h2;
    line-height: 24px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: $h3;
    line-height: 20px;
  }

  h6 {
    font-size: $kendo-font-size;
    line-height: 16px;
  }
  // Hyperlinks
  a {
    color: $kendo-color-primary;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      color: $lblhover;
    }
  }
  .linkmd {
    color: $lblhover;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      color: $kendo-color-primary;
    }
  }

  .linksm {
    color: $lblhover;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    &:hover {
      color: $kendo-color-primary;
    }
  }

  // Paragraphs & Listing
  p {
    color: $content;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: $kendo-font-size;
    line-height: 18px;
  }
  .secondary {
    color: $secondary;
  }
  .fs-12 {
    font-size: $kendo-button-sm-font-size;
  }
}
.darkhead {
  color: rgba(1, 24, 98, 0.87);
}
// write down global css inside .content-part class only

// Kendo card
kendo-card {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  //   padding: 25px;
  //   margin-bottom: 25px;
}

// text overflow
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Icons
.k-i-caret-alt-down::before {
  content: "\f0d7" !important;
  @include fico;
}
.k-calendar-header .k-i-chevron-left::before {
  content: "\f0d9" !important;
  @include fico;
  font-size: 12px;
}
.k-calendar-header .k-i-chevron-right::before {
  content: "\f0da" !important;
  @include fico;
  font-size: 12px;
}
.k-i-caret-alt-up::before {
  content: "\f0d8" !important;
  @include fico;
}
.k-i-sort-asc-small::before {
  content: "\f0de" !important;
  @include fico;
  font-size: 12px;
}
.k-i-sort-desc-small::before {
  content: "\f0dd" !important;
  @include fico;
  font-size: 12px;
}

// Toggle button geroup
.comment-toggle-group {
  display: flex;
  .k-button {
    color: $content;
    &.k-focus,
    &:focus {
      background: rgba(1, 24, 98, 0.16);
      border-color: 1px solid rgba(1, 24, 98, 0.32);
      box-shadow: none;
    }
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
      border-left: none;
    }
  }
}

// Flex
.row-reverse {
  flex-direction: row-reverse;
}
