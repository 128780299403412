$kendo-input-border-radius: 4px;
$kendo-color-primary: #2196f3;
$kendo-color-secondary: rgba(1, 24, 98, 0.6);
$kendo-color-light: #ebedf2;
$kendo-color-error: #f31700;
$kendo-color-warning: #ffc000;
$kendo-color-tertiary: rgba(1, 24, 98, 0.16);
$kendo-button-sm-font-size: 12px;
$kendo-color-info: #2196f3;

$black: #000000;
$white: #ffffff;

$kendo-font-size: 14px;
$h1: 24px;
$h2: 20px;
$h3: 16px;
$h6: $kendo-font-size;

$lblcolor: rgba(1, 24, 98, 0.6);
$lblhover: rgba(1, 24, 98, 0.74);
$content: rgba(1, 24, 98, 0.87);
$secondary: rgba(1, 24, 98, 0.6);
$darkhead: $lblhover;

//Tabstrip
$kendo-tabstrip-bg: $white;
$kendo-tabstrip-text: #011862de;
$kendo-tabstrip-border: rgba(1, 24, 98, 0.04);
$kendo-tabstrip-item-padding-x: 24px;
$kendo-tabstrip-item-padding-y: 15px;
$kendo-tabstrip-item-border-radius: 4px;
$kendo-tabstrip-item-gap: 2px;
$kendo-tabstrip-item-hover-gradient: rgba(1, 24, 98, 0.04);
$kendo-tabstrip-item-selected-border: 4px solid rgba(33, 150, 243, 0.25);

// Badges
$kendo-badge-sizes: (
  sm: (
    font-size: 12px,
    line-height: 14px,
    padding: 1px 8px,
  ),
  md: (
    font-size: 12px,
    line-height: 16px,
    padding: 0 8px,
  ),
  lg: (
    font-size: 14px,
    line-height: 20px,
    padding: 3px 8px,
  ),
);
