.mat-tab-labels {
  background: #f9f8f8;

  .mat-tab-label {
    height: auto !important;
    min-height: 48px;
    font-size: 16px;

  }

  .mat-tab-label-active {
    background: #fff;
    opacity: 1;
  }
}

.mat-tab-label-content {
  text-transform: uppercase;
  padding: 20px 0;

  .mat-badge {
    padding: 0 20px;
  }
}

.mat-tab-body-content {
  background: #fff;
}
