@mixin cscroll {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 7;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ccd1e0;
      border-radius: 10px;
    }
  }
  @mixin fico {
    font-family: "Font Awesome 6 Free";
    font-style: normal;
    color: rgba(1, 24, 98, 0.87);
    font-weight: 900;
  }