.mat-header-cell {
  font-size: 1rem;
}

.mat-cell {
  font-size: 0.8rem;
}

.mat-header-cell,
.mat-cell {
  // &:first-child {
  //   padding-right: 10px;
  // }

  &:last-child {
    // padding-left: 10px;
    text-align: right;

    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }

  // &:not(:first-child):not(:last-child) {
  //   padding-left: 10px;
  //   padding-right: 10px;
  // }
}

// Standardize tables acrosss the application
table.mat-table {
  border: 1px solid #979797;
  margin-bottom: 0.5em;
  th.mat-header-cell {
      font-size: 16px;
      color: #2A2C2E;
      background-color: #f2f8fd;
  }
  // tr:last-child td {
  //     border-bottom: 1px solid #979797;
  //     border-bottom-color: #979797;
  // }
  th.mat-header-cell, .mat-header-cell, td.mat-cell {
      border-bottom-color: #979797
  }
}
table.mat-table.dark-footer > tfoot > tr > td {
  background-color: #eeeeee;
  background: #eeeeee;
  border-bottom: inherit;
}
// Override for nested table header text alignment
th.custom-sort-header div.mat-sort-header-container, th.custom-sort-header.mat-header-cell:last-child .mat-sort-header-container, th.custom-sort-header.mat-cell:last-child .mat-sort-header-container {
  display: flex;
  justify-content: flex-start;
}