@import '~@angular/material/theming';

$nefco-custom-typography: mat-typography-config(
  $font-family: 'Roboto, sans-serif',
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(14px, 24px, 400)
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($nefco-custom-typography);

// generated these colors using https://angular-md-color.com/#/
$nefco-primary-color: (
  50: #0b54e9,
  100: #094bd1,
  200: #0842b8,
  300: #073aa0,
  400: #063187,
  500: #05286F,
  600: #041f57,
  700: #03163e,
  800: #020e26,
  900: #01050d,
  A100: #1861f5,
  A200: #3172f6,
  A400: #4983f7,
  A700: #000000,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$nefco-accent-color: (
  50: #012e75,
  100: #01388e,
  200: #0142a8,
  300: #024cc1,
  400: #0256da,
  500: #0260f3,
  600: #2b7dfd,
  700: #4b8df9,
  800: #5e9cfe,
  900: #77abfe,
  A100: #2b7dfd,
  A200: #126DFD,
  A400: #0260f3,
  A700: #90bbfe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$nefco-app-primary: mat-palette($nefco-primary-color);
$nefco-app-accent:  mat-palette($nefco-accent-color, 700);
$nefco-app-green: mat-palette($mat-green);

// The warn palette is optional (defaults to red).
$nefco-app-warn:    mat-palette($mat-red, A400);

// Create the theme object (a Sass map containing all of the palettes).
$nefco-app-theme: mat-light-theme($nefco-app-primary, $nefco-app-accent, $nefco-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($nefco-app-theme);

// Used by EnforcePermissionDirective
div.enforce-overlay {
  position: absolute;
  z-index: 999;
  cursor: not-allowed;

  width: 100%;
  height: 100%;

  overflow: hidden;

  input {
    position: absolute;
    top: -999px;
  }
}

.warn {
  color: #FDD74B;
}
