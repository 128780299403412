/* Material Theme */
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "scss/theme";


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}


::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

table {
  width: 100%;
}

a,
button:focus {
  outline: none;
}

.page-title {
  font-size: 1.5rem;
  margin: 0 0 1rem;
}

.page-title-container {
  margin-bottom: 1rem;
  align-items: center;

  .page-title {
    margin-bottom: 0;
  }
}

.tableWrapper {
  border: 1px black solid;
}

.no-results {
  text-align: center;
  font-size: 1.2rem;
  color: #999;
  font-weight: bold;
  padding: 30px;
}

.mat-row.hovered {
  background: #eee;
}

.mat-row.highlighted {
  background: #d5d5d5 !important;
}

.mat-row:nth-child(even) {
  background-color: #fafafa;
}

// Used for both client portal and main application
section.outlet-content {
  padding: 15px 20px;
  background-color: #F4F8FB;
  min-height: 450px;
}
// pus here to avoid ::ng-deep in component
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

// remove annoying increment arrows on inputs of type number
input.numeric-input[type="number"]::-webkit-outer-spin-button,
input.numeric-input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.numeric-input[type="number"] {
  -moz-appearance: textfield;
}
// Global italic style
.font-style-italic {
  font-style: italic;
}

// Global "Create Investigation" Style Button

button.investigation-button {
  color: #4b8df9;
  border: 1px solid #4b8df9;
  border-radius: 0.25em;
  padding: 0 4em;
}

button.investigation-button-disabled {
  border: 1px solid rgba(0, 0, 0, 0.26);
}

.mat-dialog-content {
  max-height: 95% !important;
}

// Need this, or the modal content isn't scrollable
.mat-dialog-container {
  height: auto !important;
}

@media print {
  body * {
    visibility: hidden;
  }
  .printable-only-content,
  .printable-only-content * {
    visibility: visible;
  }
  .printable-only-content {
    position: absolute;
    left: 0;
    top: 0;
  }
}

::-ms-reveal {
  display: none;
}

// Used by the appEnforcePermission directive to hide elements on the dom
.protected-element {
  display: none !important; // override everything else
}

// Prevent select dropdown from clipping text
.mat-select-panel {
  max-width: none !important;
}

@import "scss/tables";
@import "scss/tabs";
@import "scss/buttons";
@import "scss/cards";
@import "scss/text";
@import "scss/menu";
@import "scss/modal";
@import "scss/variables";
