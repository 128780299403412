.cdk-overlay-pane.oversized {
  .mat-dialog-container {
    width: 50vw;
  }

  &.smaller {
    .mat-dialog-container {
      width: 35vw;
    }
  }

  &.large {
    .mat-dialog-container {
      width: 80vw;
    }
  }

  &.medium {
    .mat-dialog-container {
      width: 65vw;
    }
  }
}
