.rounded-button {
  border-radius: 10em !important;
  padding: 0.25em 2.5em !important;
}

button.blue-action-button {
  background-color: #4B8DF9;
  color: #ffffff;
  padding: 0 2.5em;
  // Override material disabled styling
  &.mat-button-disabled:disabled {
    background-color: #4b8ef992;
    color: #ffffff;
  }
}